/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
  /* color-scheme: dark */
}


.canvas-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #000,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.5),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.5),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

/* .hash-span {
  margin-top: -100px;
  padding-bottom: 100px;
  display: block;
} */

.black-gradient {
  background: #000000;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #434343, #000000);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #434343, #000000);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@media (min-width: 0px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 320px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 640px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

/* 2xl */
@media (min-width: 1536px) {
  html {
    font-size: 22px;
  }
}

/* 3xl */
@media (min-width: 1792px) {
  html {
    font-size: 18px;
  }
}

/* 4xl */
@media (min-width: 2048px) {
  html {
    font-size: 26px;
  }
}

@media (min-width: 2304px) {
  html {
    font-size: 28px;
  }
}

@media (min-width: 2560px) {
  html {
    font-size: 28px;
  }
}

@media (min-width: 2816px) {
  html {
    font-size: 32px;
  }
}

@media (min-width: 3072) {
  html {
    font-size: 34px;
  }
}

@media (min-width: 3328) {
  html {
    font-size: 36px;
  }
}

@media (min-width: 3584px) {
  html {
    font-size: 38px;
  }
}

/* @keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-100px);
	}
	100% {
		transform: translatey(0px);
	}
}

#green-drop {
  animation: float 6s ease-in-out infinite;
} */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(8, 184, 131, 0.8);
  border-radius: 4px;
}

/* For Firefox */
::-moz-scrollbar {
  width: 8px;
}

::-moz-scrollbar-thumb {
  background-color: #fb923c;
  border-radius: 4px;
}


/* START OF FLIP CARD CONTENT STYLE */

.fc-item {
  position: relative;
  overflow: hidden;
  background-color: #e5e7eb;
}

.fc-frontcontent {
  overflow: hidden;
  position: relative;
  z-index: 20;
  top: 0;
  transition: transform 0.2s linear 0.2s;
}

.fc-item.flip-content .fc-frontcontent {
  transform: scaleX(0);
  transition: transform 0.2s linear 0s;
}

.fc-backcontent {
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  transform: scaleX(0) rotateY(180deg);
  transition: transform 0.2s linear 0s;
}

.fc-item.flip-content .fc-backcontent {
  transform: scaleX(-1) rotateY(180deg);
  transition: transform 0.2s linear 0.2s;
}

/* .core-team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.core-team-member {
  text-align: center;
}

.core-team-member img {
  margin-bottom: 10px;
}

.core-team-member-img::after {
  width: 200%;
  height: 20px;
  background: linear-gradient(90deg, transparent 20%, #000 20%, #000 40%, transparent 40%, transparent 60%, #000 60%, #000 80%, transparent 80%, transparent 100%);
  background-size: 10px 2px;
} */

.curved-dash-line {
  width: 2.6rem;
  height: 0.1rem;
  background: linear-gradient(90deg, transparent 20%, #000 20%, #000 40%, transparent 40%, transparent 60%, #000 60%, #000 80%, transparent 80%, transparent 100%);
  background-size: 10px 2px;
}