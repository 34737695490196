@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    /* width: 100dvw; */
    overflow-x: hidden;
    height: 100%;
}

/* canvas {
    touch-action: none;
} */